<template>

  <el-container class="container">
    <el-header class="header">
      <span class="left el-icon-arrow-left" @click="returnOn"></span>
      出库管理
    </el-header>

    <el-container style="height: calc(100vh - 110px);">
      <el-container class="container1 left-container">
        <el-main class="main left-main">
          <div class="main-title">
            基本信息
          </div>
          <div class="body">
            <div class="cell" @click="outboundDialogShow=true">
              <div class="left">入库仓库</div>
              <div class="right">{{outboundName?outboundName:'选择仓库名称'}}<span class="el-icon-arrow-right"></span></div>
            </div>
            <div class="cell">
              <div class="left">入库类型</div>
              <div class="right">
                <div :class="outboundType===0?'item item1':'item'" @click="outboundType=0">盘盈</div>
                <div :class="outboundType===1?'item item1':'item'" @click="outboundType=1">报损</div>
                <div :class="outboundType===2?'item item1':'item'" @click="outboundType=2">其他</div>
              </div>
            </div>
            <div class="cell">
              <div class="left">单据日期</div>
              <div class="right">{{timePicker}}</div>
            </div>
            <div class="mark">
              <div class="mark-title">
                备注说明
              </div>
              <div class="mark-ipt">
                <el-input type="textarea" v-model="markInput" :rows="3" resize="none" placeholder="输入备注说明"
                  maxlength="300" show-word-limit>
                </el-input>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
      <el-container class="container1 right-container">
        <el-header>
          <div class="main-title">
            商品信息
            <div class="title-right">
              <div class="btn btn1" @click="clearGoods">清空已选</div>
              <div class="btn btn2" @click="goodsDrawerShow=true">添加商品</div>
            </div>
          </div>
        </el-header>
        <el-main class="main right-main">

          <div class="body">
            <div class="card">
              <div class="card-title">
                <div class="name-close">
                  <div class="name">菲灵魔法定型发胶</div>
                  <div class="close el-icon-circle-close"></div>
                </div>
                <div class="size">500ml</div>
              </div>
              <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                  <div class="right">
                    <span class="el-icon-circle-close"></span>
                  </div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">库存数量：
                    <div class="stepper">
                      10箱
                    </div>
                  </div>
                  <div class="right">
                    出库数量：
                    <div class="stepper">
                      <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="10">
                      </el-input-number>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                  <div class="right">
                    <span class="el-icon-circle-close"></span>
                  </div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">库存数量：
                    <div class="stepper">
                      10箱
                    </div>
                  </div>
                  <div class="right">
                    出库数量：
                    <div class="stepper">
                      <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="10">
                      </el-input-number>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-title">
                <div class="name-close">
                  <div class="name">菲灵魔法定型发胶</div>
                  <div class="close el-icon-circle-close"></div>
                </div>
                <div class="size">500ml</div>
              </div>
              <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                  <div class="right">
                    <span class="el-icon-circle-close"></span>
                  </div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">库存数量：
                    <div class="stepper">
                      10箱
                    </div>
                  </div>
                  <div class="right">
                    出库数量：
                    <div class="stepper">
                      <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="10">
                      </el-input-number>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                  <div class="right">
                    <span class="el-icon-circle-close"></span>
                  </div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">库存数量：
                    <div class="stepper">
                      10箱
                    </div>
                  </div>
                  <div class="right">
                    出库数量：
                    <div class="stepper">
                      <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="10">
                      </el-input-number>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <el-footer>
      <el-button type="primary" round>
        确认出库
      </el-button>
    </el-footer>

    <!-- 仓库 -->
    <el-dialog :visible.sync="outboundDialogShow" :show-close="false" :before-close="handleClose">
      <template slot="title">
        <div class="dialog-title">
          <span></span><span class="center">选择仓库</span><span class="el-icon-close"
            @click="outboundDialogShow=false"></span>
        </div>
      </template>
      <el-container style="height:420px;">
        <el-header class="dialog-header">
          <div class="search">
            <el-input placeholder="请输入仓库名称" clearable prefix-icon="el-icon-search" v-model="outboundSearchVal">
            </el-input>
            <el-button type="primary">搜索</el-button>
          </div>
        </el-header>
        <el-main class="cell-list">
          <el-radio-group v-model="outboundRadio">
            <el-radio :label="'仓库名称'+item" v-for="(item,i) in 20" :key="i">
              <div class="dialog-title">
                仓库名称{{item}}
              </div>
            </el-radio>
          </el-radio-group>
        </el-main>
        <el-footer>
          <div class="footer">
            <div class="btn" @click="outboundConfirm">确定</div>
          </div>
        </el-footer>
      </el-container>
    </el-dialog>
    <!-- 商品 -->
    <el-drawer :visible.sync="goodsDrawerShow" :show-close="false" direction="rtl" :before-close="handleClose">
      <template slot="title">
        <div class="drawer-title">
          <span></span><span class="center">添加商品</span>
          <span class="el-icon-close" @click="goodsDrawerShow=false"></span>
        </div>
      </template>
      <el-container style="height:calc(100vh - 52px);">
        <el-header>
          <div class="search">
            <el-input placeholder="请输入商品名称搜索" clearable prefix-icon="el-icon-search" v-model="goodsSearchVal">
            </el-input>
            <el-button type="primary">搜索</el-button>
          </div>
        </el-header>
        <el-main class="cell-list">
          <el-checkbox-group v-model="goodsCheck">
            <div class="card">
              <div class="card-title">
                <div class="name-close">
                  <div class="name">菲灵魔法定型发胶</div>
                  <div class="close">
                    <el-checkbox label="1">
                    </el-checkbox>
                  </div>
                </div>
                <div class="size">500ml</div>
              </div>
              <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                  <div class="right">
                    <span class="el-icon-circle-close"></span>
                  </div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">库存数量：
                    <div class="stepper">
                      10箱
                    </div>
                  </div>
                  <div class="right">
                    出库数量：
                    <div class="stepper">
                      <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="10">
                      </el-input-number>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                  <div class="right">
                    <span class="el-icon-circle-close"></span>
                  </div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">库存数量：
                    <div class="stepper">
                      10箱
                    </div>
                  </div>
                  <div class="right">
                    出库数量：
                    <div class="stepper">
                      <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="10">
                      </el-input-number>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-title">
                <div class="name-close">
                  <div class="name">菲灵魔法定型发胶</div>
                  <div class="close">
                    <el-checkbox label="2">
                    </el-checkbox>
                  </div>
                </div>
                <div class="size">500ml</div>
              </div>
              <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                  <div class="right">
                    <span class="el-icon-circle-close"></span>
                  </div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">库存数量：
                    <div class="stepper">
                      10箱
                    </div>
                  </div>
                  <div class="right">
                    出库数量：
                    <div class="stepper">
                      <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="10">
                      </el-input-number>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                  <div class="right">
                    <span class="el-icon-circle-close"></span>
                  </div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">库存数量：
                    <div class="stepper">
                      10箱
                    </div>
                  </div>
                  <div class="right">
                    出库数量：
                    <div class="stepper">
                      <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="10">
                      </el-input-number>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-checkbox-group>
        </el-main>
        <el-footer>
          <div class="footer">
            <div class="left">共选择<b>3</b>项</div>
            <div class="btn right" @click="goodsConfirm">加入入库</div>
          </div>
        </el-footer>
      </el-container>
    </el-drawer>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        timePicker: new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? new Date().getMonth() + 1 :
          '0' + (new Date().getMonth() + 1)) + '-' + (new Date().getDate() > 9 ? new Date().getDate() : '0' +
          new Date().getDate()),
        outboundType: 0,
        markInput: '', // 留言
        stepperNum: '', // 库存数量
        outboundDialogShow: false,
        outboundSearchVal: '',
        outboundName: '',
        outboundRadio: '',
        // 添加商品
        goodsDrawerShow: false,
        goodsSearchVal: '',
        goodsName: '',
        goodsCheck: [],
      }
    },
    computed: {},
    watch: {},

    mounted() {},
    methods: {
      // 返回上级
      returnOn() {
        this.$router.go(-1)
      },
      // 清空已选
      clearGoods() {
        this.$confirm('确定要清空已选商品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '清除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消清除'
          });
        });
      },
      outboundConfirm() {
        this.outboundName = this.outboundRadio
        this.outboundDialogShow = false

      },
      goodsConfirm() {
        this.goodsDrawerShow = false
      },

      handleClose() {
        this.outboundDialogShow = false
        this.goodsDrawerShow = false
      }
    }
  };

</script>
<style lang="less" scoped>
  .container {
    height: calc(100vh);
    background-color: #f9f9f9;

    // 头部
    .el-header {
      position: relative;
      z-index: 1;
      background-color: #fff;
      font-weight: bold;
      font-size: 16px;
      color: #333;
      height: 50px !important;
      line-height: 50px;
      text-align: center;
      border-bottom: 1px solid #e9e9e9;

      .left {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: #46a6ff;
        font-size: 24px;
      }
    }

    .container1 {
      background-color: #fff;
      box-shadow: 0 0 2px #ccc;
      color: #666;

      .main-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 50px;
        border-bottom: 1px solid #f2f2f2;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;

        .title-right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-weight: normal;
          font-size: 12px;

          .btn {
            width: 70px;
            line-height: 30px;
            text-align: center;
            border: 1px solid #409eff;
            border-radius: 5px;
            margin-left: 10px;
          }

          .btn1 {
            color: #409eff;
          }

          .btn2 {
            background-color: #409eff;
            color: #fff;
          }
        }
      }

      .body {
        padding: 0 15px;
      }
    }

    .left-container {
      width: 50%;
      margin: 20px 10px 20px 20px;

      .body {
        .cell {
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 50px;
          border-bottom: 1px solid #f2f2f2;

          .right {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .item {
              border: 1px solid #f2f2f2;
              line-height: 30px;
              text-align: center;
              width: 60px;
              margin-left: 5px;
              border-radius: 30px;
              font-size: 12px;
            }

            .item1 {
              border-color: #409eff;
              color: #409eff;
              background-color: #f6faff;
            }
          }
        }

        .mark {
          margin-top: 20px;

          .mark-title {
            line-height: 40px;
          }
        }
      }
    }

    .right-container {
      width: 50%;
      margin: 20px 20px 20px 10px;

      /deep/ .el-header {
        border-bottom: 0;

        .main-title {
          border-bottom: 0;
        }
      }
    }

    .card {
      padding: 15px;
      background-color: #f9f9f9;
      border: 1px solid #f2f2f2;
      border-radius: 10px;
      padding-right: 20px;
      margin-bottom: 15px;

      .card-title {
        border-bottom: 1px solid #f2f2f2;

        .name-close {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;

          .close {
            font-size: 18px;
            color: #999;
          }
        }

        .size {
          margin-bottom: 10px;
          color: #999;

        }
      }

      .card-item {
        border-bottom: 1px solid #f2f2f2;
        padding: 10px 0;
        color: #999;

        .text {
          color: #666;
        }

        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .right {
            font-size: 18px;
            color: #999;

            span {
              margin-left: 10px;
            }
          }
        }

        .center {
          margin-bottom: 10px;
        }

        .bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          .right {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }

    .el-footer {
      width: 100%;
      background-color: #fff;
      border-top: 1px solid #f2f2f2;
      display: flex;
      justify-content: center;
      align-items: center;

      .el-button {
        width: 30%;
      }
    }

    /deep/ .el-dialog {
      min-width: 400px;

      .el-dialog__header {
        padding: 0;
        margin: 0;
      }

      .el-dialog__body {
        height: 410px;
      }

      .dialog-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;

        .center {
          font-size: 16px;
          font-weight: bold;
        }

        .el-icon-close {
          font-size: 20px;
          color: #409eff;
        }
      }

      .dialog-header.el-header {
        padding: 0 10px;
        box-sizing: content-box;
        border-bottom: none;

        .search {
          display: flex;
          justify-content: space-around;
          align-items: center;

          .el-button--primary {
            height: 40px;
            margin-left: -6px;
            z-index: 1;
            font-size: 12px;
            border-radius: 0 5px 5px 0;

          }

          .el-button--primary:focus,
          .el-button--primary:hover,
          .el-button:active,
          .el-button:focus,
          .el-button:hover {
            color: #fff;
            border-color: #409eff;
            background-color: #409eff;
          }

          /deep/ .el-input__inner {
            height: 40px;
            border: 1px solid #dcdfe6;
            border-right: 0;
            border-radius: 5px 0 0 5px;

            &:focus,
            &:hover {
              border-color: #dcdfe6;
            }
          }
        }
      }

      .cell-list {
        width: 100%;
        padding: 0 10px 10px !important;



        .el-radio-group {
          width: 100%;
          position: relative;

          .el-radio__input.is-checked+.el-radio__label {
            color: #666;
          }

          .el-radio {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #f2f2f2;
            margin-right: 0;

            &:last-child {
              border-bottom: none;
            }

            .dialog-title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 12px;
              padding: 15px 0;
            }

            .el-radio__input {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }

      }

      .el-footer {
        .footer {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 100%;


          .btn {
            width: 80%;
            text-align: center;
            border: 1px solid #409eff;
            border-radius: 50px;
            line-height: 40px;
            background-color: #409eff;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }

    /deep/ .el-drawer {
      min-width: 400px;

      .el-drawer__header {
        padding: 0;
        margin: 0;
      }

      .drawer-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid #f2f2f2;

        .center {
          font-size: 16px;
          font-weight: bold;
        }

        .el-icon-close {
          font-size: 20px;
          color: #409eff;
        }
      }

      .el-header {
        padding: 20px;
        box-sizing: content-box;

        .search {
          display: flex;
          justify-content: space-around;
          align-items: center;

          .el-button--primary {
            height: 40px;
            margin-left: -6px;
            z-index: 1;
            font-size: 12px;
            border-radius: 0 5px 5px 0;

          }

          .el-button--primary:focus,
          .el-button--primary:hover,
          .el-button:active,
          .el-button:focus,
          .el-button:hover {
            color: #fff;
            border-color: #409eff;
            background-color: #409eff;
          }

          /deep/ .el-input__inner {
            height: 40px;
            border: 1px solid #dcdfe6;
            border-right: 0;
            border-radius: 5px 0 0 5px;

            &:focus,
            &:hover {
              border-color: #dcdfe6;
            }
          }
        }
      }

      .cell-list {
        width: 100%;
        padding: 10px 20px 20px !important;

        .el-checkbox-group {
          width: 100%;
          position: relative;
          font-size: 12px;

          .el-checkbox__input.is-checked+.el-checkbox__label {
            color: #666;
          }

          .el-checkbox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 0;
            background-color: #f9f9f9;
            border: 1px solid #f2f2f2;
            border-radius: 10px;
            margin-bottom: 15px;

            .el-checkbox__input {
              position: absolute;
              right: 10px;
              top: 50%;
            }
          }

          .card {
            .card-title {
              position: relative;

              .name-close {
                padding-top: 0;

                .name {
                  font-size: 14px;
                }
              }
            }
          }
        }

      }

      .el-footer {
        .footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;


          .btn {
            width: 40%;
            text-align: center;
            border: 1px solid #409eff;
            border-radius: 50px;
            line-height: 40px;
          }

          .left {
            color: #666;

            b {
              color: #f56c6c;
            }
          }

          .right {
            background-color: #409eff;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }

    .batch-drawer {
      /deep/.el-drawer__body {
        background-color: #f9f9f9;
      }

      .el-header {
        border-bottom: none;
        background-color: #f9f9f9;

        .goods-name {
          text-align: left;
          color: #666;
          line-height: 24px;
          font-weight: normal;

          .name {
            font-size: 14px;
          }

          .size {
            font-size: 12px;
            color: #999;
          }
        }
      }

      .cell-input {
        /deep/ .el-form {
          background-color: #fff;

          .el-form-item {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 15px;
            box-sizing: border-box;


            .el-form-item__label {
              width: 30%;
            }

            .el-form-item__content {
              width: 70%;

              .el-input {
                width: 100%;

                .el-input__inner {
                  border: none;
                  text-align: right;
                  padding: 0;
                }

                .el-input__prefix {
                  display: none;
                }
              }
            }

            .stepper {
              display: flex;
              justify-content: flex-end;

              .el-input-number {
                .el-input__inner {
                  border: none;
                  text-align: center;
                }
              }
            }
          }
        }

        .hint {
          color: #ff9900;
          font-size: 12px;
          padding-left: 20px;
        }
      }

      .el-footer {
        .footer {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          .btn {
            width: 80%;
            border: 1px solid #409eff;
            border-radius: 50px;
            line-height: 40px;
            background-color: #409eff;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }
  }

  /deep/ .el-checkbox__label {
    display: none;
  }

  .stepper {

    /deep/ .el-input-number {
      width: 90px;
      height: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .el-input-number__decrease,
      .el-input-number__increase {
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        border: 1px solid #ccc;
        color: #ccc;
        font-weight: bold;
      }

      .el-input {
        width: 100px;
        height: 34px;
        background-color: transparent;

        .el-input__inner {
          height: 20px;
          line-height: 20px;
          padding: 0 15px;
          border-color: #73a0fa;
          background-color: transparent;
          color: #999;
          font-weight: bold;
          border: none;
        }
      }
    }
  }

  .el-main {
    padding: 0 !important;
  }

</style>
